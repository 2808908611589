<div class="rmd-card-header-text">
  <ng-content
    select="rmd-card-title,
    rmd-card-subtitle,
    [rmd-card-title],
    [rmd-card-subtitle],
    [rmdCardTitle],
    [rmdCardSubtitle]"
  >
  </ng-content>
</div>
<div class="rmd-card-header-actions">
  <ng-content
    select="rmd-card-actions,
    [rmd-card-actions]"
  >
  </ng-content>
</div>
