import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Directive,
  HostBinding,
  Input,
} from '@angular/core'

@Directive({
  selector: 'rmd-card-content, [rmd-card-content], [rmdCardContent]',
})
export class RmdCardContentDirective {
  @Input() withPadding = true
  @Input() withScroll = false
  @HostBinding('class') private get rmdCardContentClass() {
    const classes = ['rmd-card-content']
    if (this.withPadding) {
      classes.push('rmd-card-content--with-padding')
    }
    if (this.withScroll) {
      classes.push('rmd-card-content--with-scroll')
    }
    return classes
  }
}

@Directive({
  selector: `rmd-card-title, [rmd-card-title], [rmdCardTitle]`,
})
export class RmdCardTitleDirective {
  @HostBinding('class.rmd-card-title') private rmdCardTitleClass = true
}

@Directive({
  selector: `rmd-card-subtitle, [rmd-card-subtitle], [rmdCardSubtitle]`,
})
export class RmdCardSubtitleDirective {
  @HostBinding('class.rmd-card-subtitle') private rmdCardSubtitleClass = true
}

@Directive({
  selector: 'rmd-card-actions, [rmd-card-actions], [rmdCardActions]',
  exportAs: 'rmdCardActions',
})
export class RmdCardActionsDirective {
  @HostBinding('class.rmd-card-actions') private rmdCardActionsClass = true
}

@Directive({
  selector: 'rmd-card-footer, [rmd-card-footer], [rmdCardFooter]',
})
export class RmdCardFooterDirective {
  @HostBinding('class.rmd-card-footer') private rmdCardFooterClass = true
}

@Component({
  selector: 'rmd-card',
  exportAs: 'rmdCard',
  templateUrl: 'rmd-card.html',
  styleUrls: ['rmd-card.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdCardComponent {
  @HostBinding('class.rmd-card') private rmdCardClass = true
}

@Component({
  selector: 'rmd-card-header',
  templateUrl: 'rmd-card-header.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdCardHeaderComponent {
  @HostBinding('class.rmd-card-header') private rmdCardHeaderClass = true
}
